/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:29427e89-ce47-45c5-9e57-39a7212b6d67",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_wAu1LZDGH",
    "aws_user_pools_web_client_id": "4hgv0o4ku578pfa856d6t6ecp1",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "ogcs3upload93d8b6965e5144a0a694a064c2700c88164544-dev",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "aws_content_delivery_bucket": "ogcs3upload-20240206164949-hostingbucket-dev",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d1xqt5vk1p8ekb.cloudfront.net"
};


export default awsmobile;
